<template>
  <section>
    <a-row type="flex" justify="space-between" class="container">
      <boxTitleMoudle
        :left="'0px'"
        style="width: 15.5%"
        class="towerEquiment"
        :title="'出入口'"
      >
        <li
          :class="
            index == towerCurrent
              ? 'towerName m-b-10 active'
              : 'towerName m-b-10'
          "
          @click="changeTower(index)"
          v-for="(item, index) in towerList"
          :key="index"
        >
          <!-- <a-icon type="play-square" /> -->
          <img class="icon" src="../assets/image/bg-image/enter.png" alt="" />

          <span class="name">
            {{
              item.doorwayName.length > 10
                ? item.doorwayName.substr(0, 8) + "..."
                : item.doorwayName
            }}
          </span>
          <span class="leftLine" v-if="index == towerCurrent"></span>
          <span class="rightLine" v-if="index == towerCurrent"></span>
        </li>
      </boxTitleMoudle>

      <a-col style="width: 45.5%" class="monitor">
        <boxTitleMoudle
          :left="'-290px'"
          class="monitor_video"
          :title="'监控画面'"
        >
          <section style="width: 100%; height: 100%; padding: 10px">
            <img
              src="../assets/image/bg-image/exit_pic.png"
              alt=""
              style="width: 100%; height: 100%"
            />
          </section>
        </boxTitleMoudle>

        <boxTitleMoudle :left="'-290px'" class="real_data" :title="'人车统计'">
          <section class="car_person_list">
            <section class="person_box">
              <section class="boxs">
                <p class="num">
                  <span class="digital">{{ getStatisticalData.inNumber }}</span>
                  人
                </p>
                <p>在场人数</p>
              </section>
              <section class="boxs">
                <p class="num">
                  <span class="digital">{{
                    getStatisticalData.countNumber
                  }}</span>
                  人
                </p>
                <p>进场</p>
              </section>
              <section class="boxs">
                <p class="num">
                  <span class="digital">{{
                    getStatisticalData.outNumber
                  }}</span>
                  人
                </p>
                <p>离场</p>
              </section>
            </section>
            <section class="car_box">
              <section class="boxs">
                <p class="num">
                  <span class="digital">{{
                    getStatisticalData.carInNumber
                  }}</span>
                  辆
                </p>
                <p>在场车辆</p>
              </section>
              <section class="boxs">
                <p class="num">
                  <span class="digital">{{
                    getStatisticalData.carCountNumber
                  }}</span>
                  辆
                </p>
                <p>进场</p>
              </section>
              <section class="boxs">
                <p class="num">
                  <span class="digital">{{
                    getStatisticalData.carOutNumber
                  }}</span>
                  辆
                </p>
                <p>离场</p>
              </section>
            </section>
          </section>

          <section class="pei_list">
            <div>
              <div id="person_type" style="width: 100%; height: 120px"></div>
            </div>
            <div>
              <div id="person_type_1" style="width: 100%; height: 120px"></div>
            </div>

            <div>
              <div id="person_type_2" style="width: 100%; height: 120px"></div>
            </div>
            <div>
              <div id="person_type_3" style="width: 100%; height: 120px"></div>
            </div>
          </section>
        </boxTitleMoudle>
      </a-col>
      <a-col style="width: 38%" class="warnningData">
        <boxTitleMoudle :left="'-230px'" :title="'出入口预警'" class="warnning">
          <section style="height: 100%">
            <div style="width: 100%; text-align: center; height: 93%">
              <li
                class="flex-bet"
                style="
                  color: #95adce;
                  display: flex;
                  justify-content: space-between;
                  text-align: center;
                "
              >
                <span style="width: 25%">事件名称</span>
                <span style="width: 25%">事件描述</span>
                <span style="width: 25%">预警时间</span>
                <span style="width: 25%">预警地点</span>
              </li>
              <div style="overflow: hidden; height: 90%">
                <!-- <div
                  ref="contlist"
                  id="contBox"
                  class="contBox"
                  v-on:mouseover="addActive($event)"
                  v-on:mouseout="removeActive($event)"
                >
                  <li
                    class="flex-bet safeData"
                    v-for="(item, index) in InOutAlarmRecordList"
                    :key="index"
                  >
                    <span class="text-overflow" style="width: 20%">{{
                      item.alarmName
                    }}</span>
                    <span class="text-overflow" style="width: 30%">{{
                      item.alarmMessage
                    }}</span>
                    <span class="text-overflow" style="width: 30%">{{
                      item.createTime
                    }}</span>
                    <span class="text-overflow" style="width: 20%">{{
                      item.alarmArea
                    }}</span>
                  </li>
                </div> -->

                <!--    alarmName: item.alarmName,
                  alarmMessage: item.alarmMessage,
                  createTime: item.createTime,
                  alarmArea: item.alarmArea, -->
                <textData :length="7" :textData="InOutAlarmRecordList">
                  <template v-slot:alarmName="slotProps">
                    <span
                      class="text-overflow"
                      style="flex: 1; line-height: 30px; height: 30px"
                      :title="slotProps.val"
                      >{{ slotProps.val }}</span
                    >
                  </template>
                  <template v-slot:alarmMessage="slotProps">
                    <span
                      class="text-overflow"
                      style="flex: 1; line-height: 30px; height: 30px"
                      :title="slotProps.val"
                      >{{ slotProps.val }}</span
                    >
                  </template>
                  <template v-slot:createTime="slotProps">
                    <span
                      class="text-overflow"
                      style="flex: 1; line-height: 30px; height: 30px"
                      :title="slotProps.val"
                      >{{ slotProps.val }}</span
                    >
                  </template>
                  <template v-slot:alarmArea="slotProps">
                    <span
                      class="text-overflow"
                      style="flex: 1; line-height: 30px; height: 30px"
                      :title="slotProps.val"
                      >{{ slotProps.val }}</span
                    >
                  </template>
                  <!-- <template v-slot:alarmName="slotProps">
                    <span>{{slotProps.val}}</span>
                  </template> -->
                </textData>
              </div>
            </div>
          </section>
        </boxTitleMoudle>

        <boxTitleMoudle
          :left="'-230px'"
          :title="'人车记录'"
          class="warnning_tendency"
        >
          <section style="height: 88%">
            <a-row type="flex" justify="end">
              <a-radio-group
                default-value="person"
                @change="changeType"
                size="small"
              >
                <a-radio-button value="person"> 人员 </a-radio-button>
                <a-radio-button value="car"> 车辆 </a-radio-button>
              </a-radio-group>
            </a-row>
            <!-- 人员 -->
            <div
              v-if="selectType == 'person'"
              style="width: 100%; text-align: center; height: 88%"
            >
              <li
                class="flex-bet"
                style="
                  color: #95adce;
                  display: flex;
                  justify-content: space-between;
                  text-align: center;
                "
              >
                <!-- <span
                  style="flex: 1"
                  v-for="(item, index) in titleList"
                  :key="index"
                  >{{ item }}</span
                > -->
                <span style="flex: 1">识别照片</span>
                <span style="flex: 1">姓名</span>
                <span style="flex: 1">班组</span>
                <span style="flex: 1">职位/工种</span>

                <span style="flex: 1">时间</span>
                <span style="flex: 1">出入口名称</span>
              </li>
              <div style="overflow: hidden; height: 88%">
                <div>
                  <textData :length="3" :textData="getPersonnelRecordsList">
                    <template v-slot:imgList="slotProps">
                      <span
                        class="text-overflow"
                        style="flex: 1; padding: 4px 0"
                      >
                        <img
                          v-for="(item, index) in slotProps.val"
                          :key="index"
                          style="width: 30px; height: 40px"
                          :src="item"
                          alt=""
                        />
                      </span>
                    </template>
                    <template v-slot:personnelName="slotProps">
                      <span
                        class="text-overflow"
                        :title="slotProps.val"
                        style="
                          flex: 1;
                          height: 40px;
                          line-height: 40px;
                          padding: 4px 0;
                        "
                      >
                        {{ slotProps.val }}
                      </span>
                    </template>
                    <template v-slot:teamName="slotProps">
                      <span
                        class="text-overflow"
                        :title="slotProps.val"
                        style="
                          flex: 1;
                          height: 40px;
                          line-height: 40px;
                          padding: 4px 0;
                        "
                      >
                        {{ slotProps.val }}
                      </span>
                    </template>
                    <template v-slot:jobName="slotProps">
                      <span
                        class="text-overflow"
                        :title="slotProps.val"
                        style="
                          flex: 1;
                          height: 40px;
                          line-height: 40px;
                          padding: 4px 0;
                        "
                      >
                        {{ slotProps.val }}
                      </span>
                    </template>
                    <template v-slot:inOutType="slotProps">
                      <span
                        class="text-overflow"
                        :title="
                          slotProps.val.inOutType == '1'
                            ? '进入工地'
                            : '离开工地' + slotProps.val.createTime
                        "
                        style="flex: 1; line-height: 20px; padding: 4px 0"
                        >{{
                          slotProps.val.inOutType == "1"
                            ? "进入工地"
                            : "离开工地"
                        }}<br />{{ slotProps.val.createTime }}</span
                      >
                    </template>
                    <template v-slot:doorwayName="slotProps">
                      <span
                        class="text-overflow"
                        :title="slotProps.val"
                        style="
                          flex: 1;
                          height: 40px;
                          line-height: 40px;
                          padding: 4px 0;
                        "
                      >
                        {{ slotProps.val }}
                      </span>
                    </template>
                  </textData>
                </div>
              </div>
            </div>
            <!-- 车辆 -->
            <div v-else style="width: 100%; text-align: center; height: 88%">
              <li
                class="flex-bet"
                style="
                  color: #95adce;
                  display: flex;
                  justify-content: space-between;
                  text-align: center;
                "
              >
                <span class="text-overflow" style="flex: 1">识别照片</span>
                <span class="text-overflow" style="flex: 1">车牌号</span>
                <span class="text-overflow" style="flex: 1">车辆性质</span>

                <span class="text-overflow" style="flex: 1">时间</span>
                <span class="text-overflow" style="flex: 1">出入口名称</span>
              </li>

              <div style="overflow: hidden; height: 90%">
                <div>
                  <!-- <div
                    v-for="(item, index) in getCarRecordsList"
                    :key="index"
                    style="height: 29.33%"
                  >
                    <li class="flex-bet safeData">
                      <span style="flex: 1">
                        <img
                          style="height: 30px; width: 60px"
                          :src="item.logImage"
                          alt=""
                        />
                      </span>
                      <span class="text-overflow" style="flex: 1">{{
                        item.carNumber
                      }}</span>
                      <span class="text-overflow" style="flex: 1">{{
                        item.carStatus == "0" ? "正常" : "陌生"
                      }}</span>
                      <span
                        class="text-overflow"
                        style="flex: 1; line-height: 20px"
                        >{{ item.inOutType == "1" ? "进入" : "离开" }}<br />{{
                          item.dateTime
                        }}</span
                      >
                      <span class="text-overflow" style="flex: 1">{{
                        item.doorwayName
                      }}</span>
                    </li>
                  </div> -->

                  <textData :length="3" :textData="getCarRecordsList">
                    <template v-slot:logImage="slotProps">
                      <span
                        class="text-overflow"
                        style="flex: 1; padding: 6px 0"
                      >
                        <img
                          style="width: 60px; height: 38px"
                          :src="slotProps.val"
                          alt=""
                        />
                      </span>
                    </template>
                    <template v-slot:carNumber="slotProps">
                      <span
                        class="text-overflow"
                        style="
                          flex: 1;
                          height: 40px;
                          line-height: 40px;
                          padding: 6px 0;
                        "
                        :title="slotProps.val"
                      >
                        {{ slotProps.val }}
                      </span>
                    </template>
                    <template v-slot:carStatus="slotProps">
                      <span
                        class="text-overflow"
                        style="
                          flex: 1;
                          height: 40px;
                          line-height: 40px;
                          padding: 6px 0;
                        "
                        :title="slotProps.val"
                      >
                        {{ slotProps.val == "0" ? "正常" : "陌生" }}
                      </span>
                    </template>
                    <template v-slot:inOutType="slotProps">
                      <span
                        class="text-overflow"
                        style="
                          flex: 1;
                          height: 40px;
                          line-height: 18px;
                          padding: 6px 0;
                        "
                        :title="
                          slotProps.val.inOutType == '1'
                            ? '进入'
                            : '离开' + slotProps.val.dateTime
                        "
                      >
                        {{ slotProps.val.inOutType == "1" ? "进入" : "离开"
                        }}<br />{{ slotProps.val.dateTime }}
                      </span>
                    </template>
                    <template v-slot:doorwayName="slotProps">
                      <span
                        class="text-overflow"
                        style="
                          flex: 1;
                          height: 40px;
                          line-height: 40px;
                          padding: 6px 0;
                        "
                        :title="slotProps.val"
                        >{{ slotProps.val }}</span
                      >
                    </template>
                  </textData>
                </div>
              </div>
            </div>
          </section>
        </boxTitleMoudle>

        <boxTitleMoudle
          :left="'-230px'"
          :title="'预警趋势'"
          class="unusual_tendency"
        >
          <span class="echarts_title">异常次数</span>
          <div id="unusual" style="width: 100%; height: 90%"></div>
        </boxTitleMoudle>
      </a-col>
    </a-row>
  </section>
</template>
<script>
import boxTitleMoudle from "@/components/BaseBoxTitleMoudle.vue";
import textData from "@/components/textData.vue";
export default {
  data() {
    return {
      towerList: [],
      towerCurrent: 0,
      getPersonnelRecordsList: [],
      getCarRecordsList: [],
      selectType: "person",

      InOutAlarmRecordList: [],
      getStatisticalData: {},
      // 计时器
      scrollTime: null,
      distance: 0,
      // 计时器
      scrollPersonTime: null,
      personDistance: 0,
    };
  },
  components: {
    boxTitleMoudle,
    textData,
  },
  mounted() {
    // this.drawTendencyData();
    this.drawUnusualData();
  },
  created() {
    // 出入口列表
    this.doorwayList();
    // 人员进出记录
    this.getPersonnelRecords();
    // 车辆进出记录
    this.getCarRecords();
    // 出入口预警
    this.getInOutAlarmRecordList();
    // 预警趋势
    this.getInOutAlarmTrend();
    // 人车统计
    this.getStatistical();
  },
  computed: {
    picServer() {
      return this.$store.state.picServer;
    },
  },
  methods: {
    // 出入口列表
    doorwayList() {
      this.$api.doorwayList().then((res) => {
        if (res.code == "200") {
          this.towerList = res.data.slice(0, 12);
        }
      });
    },
    // 人员进出
    getPersonnelRecords() {
      this.$api.getPersonnelRecords().then((res) => {
        if (res.code == "200") {
          if (res.data.length > 0 && res.data) {
            res.data.forEach((item) => {
              this.getPersonnelRecordsList.push({
                imgList: [
                  this.picServer + item.avatar,
                  require("../assets/image/bg-image/line.png"),
                  this.picServer + item.photoUrl,
                ],
                personnelName: item.personnelName,
                teamName: item.teamName,
                jobName: item.jobName,
                inOutType: {
                  inOutType: item.inOutType,
                  createTime: item.createTime,
                },
                doorwayName: item.doorwayName,
              });
            });
          }
        }
      });
    },
    // 车辆进出
    getCarRecords() {
      this.$api.getCarRecords().then((res) => {
        if (res.code == "200") {
          if (res.data.length > 0 && res.data) {
            res.data.forEach((item) => {
              this.getCarRecordsList.push({
                logImage: this.picServer + item.logImage,
                carNumber: item.carNumber,
                carStatus: item.carStatus,
                inOutType: {
                  inOutType: item.inOutType,
                  dateTime: item.dateTime,
                },
                doorwayName: item.doorwayName,
              });
            });
          }
          // this.getCarRecordsList = res.data.slice(0, 3);
          // this.getCarRecordsList = res.data;
        }
      });
    },
    // 出入口预警
    getInOutAlarmRecordList() {
      this.$api
        .getInOutAlarmRecordList({ type: "1" })
        .then((res) => {
          if (res.code == "200") {
            if (res.data.length > 0 && res.data) {
              // this.InOutAlarmRecordList = res.data.slice(0, 6);
              res.data.forEach((item) => {
                this.InOutAlarmRecordList.push({
                  alarmName: item.alarmName,
                  alarmMessage: item.alarmMessage,
                  createTime: item.createTime,
                  alarmArea: item.alarmArea,
                });
              });
            }
          }
        })
        .then(() => {
          // this.ScrollUp();
        });
    },
    // 预警趋势
    getInOutAlarmTrend() {
      this.$api.getInOutAlarmTrend({ type: "1" }).then((res) => {
        if (res.code == "200") {
          this.drawUnusualData(res.data);
          // this.InOutAlarmRecordList = res.data.slice(0, 6);
        }
      });
    },
    // 人车统计
    getStatistical() {
      this.$api.getStatistical().then((res) => {
        if (res.code == "200") {
          this.getStatisticalData = res.data;

          // 人员类型
          let personType = [
            { value: this.getStatisticalData.manager, name: "管理员" },
            { value: this.getStatisticalData.workers, name: "务工人员" },
          ];
          // 职位类型
          let positionTyep = [];
          let jobMap =
            this.getStatisticalData.jobMap.length > 0
              ? this.getStatisticalData.jobMap
              : [{ jobName: "暂无数据", jobNumber: "100" }];

          jobMap.forEach((item) => {
            positionTyep.push({ name: item.jobName, value: item.jobNumber });
          });
          // 单位类型
          let companyType = [];
          let companyTypeMap =
            this.getStatisticalData.companyTypeMap.length > 0
              ? this.getStatisticalData.companyTypeMap
              : [{ companyTypeName: "暂无数据", typeNumber: "100" }];
          companyTypeMap.forEach((item) => {
            companyType.push({
              name: item.companyTypeName,
              value: item.typeNumber,
            });
          });
          // 车辆类型
          let carType = [];
          let carTypeMap =
            this.getStatisticalData.carTypeMap.length > 0
              ? this.getStatisticalData.carTypeMap
              : [{ carTypeName: "暂无数据", carTypeNumber: "100" }];
          carTypeMap.forEach((item) => {
            carType.push({
              name: item.carTypeName,
              value: item.carTypeNumber,
            });
          });
          this.person_type("person_type", personType, "人员类型");
          this.person_type("person_type_1", positionTyep, "职位类型");
          this.person_type("person_type_2", companyType, "单位类型");
          this.person_type("person_type_3", carType, "车辆类型");
        }
      });
    },

    // 选择人员进出 车辆进出
    changeType(e) {
      this.selectType = e.target.value;
      this.selectType == "person"
        ? this.getPersonnelRecords()
        : this.getCarRecords();
    },
    changeTower(key) {
      this.towerCurrent = key;
    },
    // 异常趋势
    drawUnusualData(data) {
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(document.getElementById("unusual"));
      let orgIcon =
        '<svg width="11" height="10" viewBox="0 0 11 10" xmlns="http://www.w3.org/2000/svg"><g transform="translate(.719 .164)" fill="none" fill-rule="evenodd"><circle stroke="#D36218" fill="#323538" cx="4.833" cy="4.833" r="4.333"/><circle fill="#D36218" cx="4.833" cy="4.833" r="1.758"/></g></svg>';
      orgIcon = encodeURIComponent(orgIcon); // 转译
      orgIcon = "data:image/svg+xml;utf8," + orgIcon; // 添加url前缀
      orgIcon = "image://" + orgIcon; // 添加ECharts需要的前缀
      let dateTime = [];
      let value = [];
      if (data && data.length > 0) {
        data.forEach((item) => {
          dateTime.push(item.dateTime);
          value.push(item.value);
        });
      }
      // 绘制图表
      myChart.setOption({
        tooltip: {
          trigger: "item",
          formatter: function (arg) {
            let html = dateTime[arg["dataIndex"]] + "<br>";
            html += "异常:" + value[arg["dataIndex"]] + "<br>";

            return html;
          },
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: dateTime,
          axisLine: {
            lineStyle: {
              // 设置x轴颜色
              color: "rgba(255, 255, 255, 0.5)",
            },
          },

          splitLine: {
            show: true,
            lineStyle: {
              color: "rgba(255, 255, 255, 0.05)",
            },
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "0%",
          top: "20%",
          containLabel: true,
        },
        yAxis: {
          type: "value",
          axisLine: {
            lineStyle: {
              // 设置x轴颜色
              color: "rgba(255, 255, 255, 0.5)",
            },
          },

          splitLine: {
            show: true,
            lineStyle: {
              color: "rgba(255, 255, 255, 0.05)",
            },
          },
        },
        series: [
          {
            data: value,
            type: "line",
            smooth: true,
            symbolSize: 10,
            symbol: orgIcon,
            // areaStyle: { color: "rgba(211,98,24,1)" },
            itemStyle: {
              normal: {
                // 拐点上显示数值
                label: {
                  show: false,
                },
                borderColor: "rgba(87,247,255,1)", // 拐点边框颜色
                lineStyle: {
                  //   width: 5, // 设置线宽
                  type: "dotted", //'dotted'虚线 'solid'实线
                },
              },
            },
          },
        ],
        color: ["rgba(211,98,24,1)"],
      });

      //建议加上以下这一行代码，不加的效果图如下（当浏览器窗口缩小的时候）。超过了div的界限（红色边框）
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },

    // 人员类型
    person_type(id, data, title) {
      let myChart = this.$echarts.init(document.getElementById(id));
      console.log(data, "datadata");
      let color = [
        "rgba(151,254,247,1)",
        "rgba(1,194,255,1)",
        "rgba(2, 181, 255)",
        "#8f74ba",
        "#6d7fff",
      ];
      let nocolor = ["#ccc"];
      // 绘制图表
      //   let bottomTitle = {
      //     text: "262",
      //     textStyle: {
      //       color: "#fff",
      //       fontSize: 14,
      //       align: "center"
      //     }
      //   };
      let topTitle = {
        text: title,
        textStyle: {
          fill: "#fff",
          fontSize: 12,
        },
      };
      myChart.setOption({
        // title: {
        //   text: bottomTitle.text,
        //   left: "center",
        //   top: "52%",
        //   textStyle: {
        //     color: "#fff",
        //     fontSize: 14,
        //     align: "center"
        //   }
        // },
        // tooltip: {
        //   trigger: "item",
        //   formatter: "{a} <br/>{b}: {c}",
        // },
        graphic: {
          type: "text",
          left: "center",
          top: "48%",
          style: {
            text: topTitle.text,
            textAlign: "center",
            fill: "#fff",
            fontSize: "12",
            //fontWeight: 500,
          },
        },
        series: [
          {
            name: title,
            type: "pie",
            radius: ["60%", "70%"],
            color: data[0].name == "暂无数据" ? nocolor : color,
            avoidLabelOverlap: false,

            graphic: {
              type: "text",
              left: "center",
              top: "45%",
              style: {
                text: "topTitle",
                textAlign: "center",
                fill: "#fff",
                fontSize: "12",
                //fontWeight: 500,
              },
            },
            label: {
              show: true,
              position: "center",
              normal: {
                formatter(v) {
                  let text = v.name;

                  if (data[0].name == "暂无数据") {
                    return text.length < 2
                      ? text
                      : `${text.slice(0, 18)}\n${text.slice(18)}`;
                  } else {
                    return text.length < 2
                      ? text
                      : `${text.slice(0, 18)}\n${text.slice(18)}` +
                          v.percent +
                          `%`;
                  }
                },
              },
            },
            // emphasis: {
            //   label: {
            //     show: true,
            //     fontSize: "30",
            //     fontWeight: "bold"
            //   }
            // },

            data: data,
          },
        ],
      });

      //建议加上以下这一行代码，不加的效果图如下（当浏览器窗口缩小的时候）。超过了div的界限（红色边框）
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.contBox {
  // transition: all 0.5s ease-in-out;
  transition: all 0.5s linear;
  height: 100%;
}
.text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
@font-face {
  font-family: "digital";
  src: url("../views/monitor_screen/font/digital.ttf") format("truetype");
}
.digital {
  font-family: digital;
  font-size: 26px;
  font-style: normal;
  font-weight: 800;
}
li {
  list-style: none;
}
.container {
  height: 98%;
  margin-top: 30px;
  // 塔吊设备
  .towerEquiment {
    background: url("../assets/image/bg-image/left_bg.png") no-repeat;
    background-size: 100% 100%;
    // padding: 80px 20px 0px 20px;
    position: relative;
    .title {
      position: absolute;
      top: -46px;
      left: 2px;
      img {
        width: 100%;
      }
    }
    .towerName {
      height: 40px;
      line-height: 40px;
      background-color: rgba(28, 61, 108, 1);
      color: rgba(255, 255, 255, 1);
      width: 100%;
      display: flex;
      justify-content: space-around;
      cursor: pointer;
      position: relative;
      margin-top: 10px;
      //   &:hover {
      //     background-color: rgba(63, 114, 186, 0.4);
      //   }
      .icon {
        width: 20%;
        text-align: center;
      }
      .name {
        width: 70%;
        font-size: 16px;
      }
      .leftLine {
        display: inline-block;
        height: 40px;
        width: 2px;
        background-color: rgba(63, 114, 186, 1);
        position: absolute;
        left: -6px;
      }
      .rightLine {
        display: inline-block;
        height: 40px;
        width: 2px;
        background-color: rgba(63, 114, 186, 1);
        position: absolute;
        right: -8px;
      }
    }
    .active {
      background: rgba(63, 114, 186, 1);
    }
  }
  //   视频监控
  .monitor {
    height: 100%;
    .monitor_video {
      position: relative;
      height: 50%;

      background-size: 100% 100%;

      margin-bottom: 20px;
      .title {
        position: absolute;
        top: -43px;
        left: 2px;
        img {
          width: 56%;
        }
      }
    }
    .real_data {
      position: relative;
      height: 47%;
      .title {
        position: absolute;
        top: -43px;
        left: 2px;
        img {
          width: 56%;
        }
      }
      .car_person_list {
        display: flex;
        justify-content: space-around;
        height: 72px;
        margin-top: 20px;
        .person_box {
          width: 40%;
          // background-color: rgba(39, 73, 121, 1);
          display: flex;
          .boxs {
            flex: 1;
            text-align: center;
            margin-right: 10px;
            background: #274979;
            .num {
              font-size: 22px;
              color: rgba(61, 127, 255, 1);
              padding-top: 8px;
            }
            p {
              margin: 0;
              height: 36px;
              line-height: 36px;
            }
          }
        }
        .car_box {
          width: 40%;
          // background-color: rgba(39, 73, 121, 1);
          display: flex;
          .boxs {
            flex: 1;
            text-align: center;
            margin-right: 10px;
            background: #274979;
            .num {
              font-size: 22px;
              color: rgba(61, 127, 255, 1);
              padding-top: 8px;
            }
            p {
              margin: 0;
              height: 36px;
              line-height: 36px;
            }
          }
        }
      }
      .pei_list {
        width: 100%;

        display: flex;
        justify-self: start;
        flex-wrap: wrap;
        > div {
          width: 50%;
        }
      }
    }
  }

  //   预警
  .warnningData {
    height: 100%;
    .warnning {
      height: 32%;
      margin-bottom: 20px;
      position: relative;
      .title {
        position: absolute;
        top: -43px;
        left: 2px;
        img {
          width: 56%;
        }
      }

      .flex-bet {
        display: flex;
        justify-content: space-between;
        text-align: center;
        height: 24px;
        line-height: 24px;
        margin-top: 20px;
      }
      .safeData {
        margin-top: 4px;
        background: #193160;
        margin-top: 4px;
        height: 15%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .warnning_tendency {
      height: 35%;
      margin-bottom: 20px;
      position: relative;
      .title {
        position: absolute;
        top: -43px;
        left: 2px;
        img {
          width: 56%;
        }
      }

      .flex-bet {
        display: flex;
        justify-content: space-between;
        text-align: center;
        height: 44px;
        line-height: 44px;

        // span {
        //   flex: 1;
        // }
      }
      .safeData {
        background: #193160;
        margin: 4px 0px;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .unusual_tendency {
      height: 28%;
      position: relative;
      .title {
        position: absolute;
        top: -43px;
        left: 2px;
        img {
          width: 56%;
        }
      }

      .echarts_title {
        float: right;
        &::before {
          content: "";
          width: 8px;
          display: inline-block;
          height: 8px;
          background: rgba(211, 98, 24, 1);
          margin-right: 8px;
          border-radius: 1px;
        }
      }
    }
  }
}
.animation {
  height: 100px;
  animation: upMove 20s linear infinite;
}

// 动画
@keyframes upMove {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(-400px);
  }
}
/deep/.ant-radio-button-wrapper {
  background: #1c3d6c;
  border: 1px solid #1c3d6c;
}
/deep/.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background: #1c3d6c;
  border-color: #40a9ff;
}
/deep/.ant-radio-button-wrapper:not(:first-child)::before {
  background: #1c3d6c;
}
/deep/.ant-radio-group-small .ant-radio-button-wrapper {
  color: #fff;
}
/deep/.ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
  color: #40a9ff !important;
}
</style>